//我的订单-用车订单列表
import consumer_car_order_carOrderList from '@/lib/data-service/haolv-default/consumer_car_order_carOrderList'
import consumer_trains_order_applyForCancel from '@/lib/data-service/haolv-default/consumer_trains_order_applyForCancel' // 取消订单
import Pagination from '@/component/pagination/index.vue'

export default {
    data () {
        return {
            loading: false,
            params: {
                currentPage: 1,         // 查询页码
                pageSize: 10,           // 查询条数
                orderNo: '',            // 订单编号
                evectionType: '',       // 预定方式
                orderStatus: 2,         // 订单状态-查询  0：全部 1：已完成 2：代付款 3：未完成
                orderBy: '',            // 排序条件
                startDate: '',          // 开始日期-查询
                endDate: '',            // 结束日期-查询
                totalCount: 0,
            },
            scheduledWayList: [
                {value: '', label: '全部'},
                {value: 2, label: '因公出差'},
                {value: 1, label: '因私出行'}
            ],
            orderList: [],
            reloadTicketFun: '',
            reloadNum: 0,
            showLoading: true
        }
    },
    components: {
        Pagination,

    },
    created () {},
    mounted () {

    },
    activated () {
        this.getTrainsOrderList()
        this.reloadList()
    },
    deactivated() {
        clearInterval(this.reloadTicketFun)
    },
    destroyed () {
        clearInterval(this.reloadTicketFun)
    },
    watch: {},
    computed: {},
    filters: {

    },
    methods: {
        search() {
            this.params.currentPage = 1;
            this.getTrainsOrderList()
        },
        getTrainsOrderList() {
            if (this.showLoading) {
                this.loading = true
            }
            consumer_car_order_carOrderList(this.params).then(res => {
                this.loading = false;
                this.showLoading = true;
                this.orderList = res.datas.carOrderList.list;
                this.params.totalCount = res.datas.carOrderList.totalCount
            }).catch(() => {
                this.loading = false;
                this.showLoading = true;
                this.orderList = [];
                this.params.totalCount = 0
            })
        },
        goDetail(val) {
            this.$router.push({
                name: 'admin-my-order-car-detail',
                query: {
                    orderNo: val.orderNo
                }
            })
        },
        reloadList() {
            this.reloadTicketFun = setInterval(() => {
                this.reloadNum++
                if (this.reloadNum === 60) {
                    this.showLoading = false
                    this.getTrainsOrderList()
                    // 页面停留超过10分钟，正在为您重新查询
                    this.reloadNum = 0
                }
            }, 1000)
        },
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
